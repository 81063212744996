<template>
  <div class="main-section" id="about">
    <div class="container py-5">
      <!-- Intro Text -->
      <div
        class="text-center mb-5 wow animate__animated animate__fadeInUp animate__fast"
      >
        <h2>Our Comprehensive Services</h2>
        <p>
          At Golden Palms Home Health Care, Inc., we offer a range of services
          tailored to meet the diverse needs of our patients. Explore our
          specialized services designed to enhance your health and well-being
          through our dedicated care approach.
        </p>
      </div>

      <!-- 2x2 Block -->
      <div class="row gy-4">
        <div
          class="col-lg-6 col-md-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="d-flex flex-column-reverse flex-md-row">
            <div class="col col-image">
              <div class="image-wrapper">
                <img src="@/assets/Home/4.jpg" alt="Service 1" />
              </div>
            </div>
            <div class="col col-text-section">
              <h3>Personalized Health Assessments</h3>
              <p>
                Our health assessments are tailored to meet your individual
                needs, ensuring that every aspect of your health is addressed
                with precision and care.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="d-flex flex-column-reverse flex-md-row">
            <div class="col col-text-section">
              <h3>Expert Medical Consultations</h3>
              <p>
                Receive expert advice and treatment for complex health
                conditions. Our team is dedicated to providing a customized care
                plan that fits your specific requirements.
              </p>
            </div>
            <div class="col col-image">
              <div class="image-wrapper">
                <img src="@/assets/Home/3.jpg" alt="Service 2" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="d-flex flex-column-reverse flex-md-row">
            <div class="col col-image">
              <div class="image-wrapper">
                <img src="@/assets/Home/2.jpg" alt="Service 3" />
              </div>
            </div>
            <div class="col col-text-section">
              <h3>Comprehensive Wellness Programs</h3>
              <p>
                Our wellness programs are designed to improve your overall
                health and well-being through a combination of preventive care,
                education, and personalized support.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 wow animate__animated animate__fadeInUp animate__fast"
        >
          <div class="d-flex flex-column-reverse flex-md-row">
            <div class="col col-text-section">
              <h3>Innovative Treatment Options</h3>
              <p>
                We offer a range of innovative treatment options to address
                various health concerns, utilizing the latest advancements in
                medical technology and techniques.
              </p>
            </div>
            <div class="col col-image">
              <div class="image-wrapper">
                <img src="@/assets/Home/1.jpg" alt="Service 4" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Special Therapy Services -->
      <div
        class="text-center mt-5 wow animate__animated animate__fadeInUp animate__fast"
      >
        <h2>Specialized Therapy Services</h2>
        <p>
          At Golden Palms Home Health Care, Inc., we combine cutting-edge
          knowledge with a warm smile. Our specialized therapy services are
          tailored to provide the highest quality of rehabilitative care:
        </p>
        <div class="row gy-4">
          <div class="col-lg-4 col-md-12">
            <div class="info-box">
              <h3>Physical Therapy</h3>
              <p>
                Our Physical Therapists deliver physician-prescribed treatments
                aimed at restoring muscle and joint functions. This helps you
                regain mobility, strength, and overall comfort.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="info-box">
              <h3>Occupational Therapy</h3>
              <p>
                Our Occupational Therapists assist you in regaining essential
                skills for daily self-care and activities, empowering you to
                live independently and enjoy a better quality of life.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="info-box">
              <h3>Speech Therapy</h3>
              <p>
                Our Speech Therapists support patients with impaired
                communication skills, helping them find new ways to express
                their needs and feelings, thereby improving their interaction
                and quality of life.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Service Details -->
      <div
        class="skilled-care-section mt-5 wow animate__animated animate__fadeInUp animate__fast"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <h2 class="section-title">
                Skilled Care & Essential Instructions
              </h2>
              <p class="section-description">
                Golden Palms Home Health Care, Inc. offers a friendly and
                respectful environment, with staff fluent in multiple languages,
                including English, Spanish, Armenian, Russian, and Tagalog. Our
                skilled nursing services include:
              </p>
            </div>
            <div class="col-lg-6">
              <ol class="service-list">
                <li>Treatment Injections</li>
                <li>Dressing Change</li>
                <li>Catheter Care</li>
                <li>Supervision of Medications</li>
                <li>Restorative Care</li>
                <li>Family/Patient Health Teaching</li>
                <li>Counseling</li>
                <li>Diabetic Teaching</li>
                <li>Drawing Blood Lab Work</li>
                <li>Wound Care</li>
                <li>Ostomy Care</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <!-- Text Block -->
      <div
        class="text-center mt-4 wow animate__animated animate__fadeInUp animate__fast"
      >
        <h2>Finest Quality of Health Care</h2>
        <p class="lead">
          At Golden Palms Home Health Care, Inc., our patients enjoy the
          convenience of a hassle-free medical visit. Our goal is to guarantee
          satisfaction with finely trained personnel providing the finest
          quality of health care around the clock.
        </p>
      </div>

      <div
        class="text-center mt-4 wow animate__animated animate__fadeInUp animate__fast"
      >
        <a href="/#contact" class="btn btn-get">Get in Touch</a>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";

export default {
  name: "HomeHealthCare",
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.skilled-care-section {
  background-color: #ffffff; /* Белый фон */
  padding: 40px 20px;
}

.section-title {
  font-size: 2rem;
  color: #343a40; /* Темный цвет для заголовка */
  margin-bottom: 1rem;
}

.section-description {
  font-size: 1.125rem;
  color: #495057; /* Темный цвет для описания */
  margin-bottom: 2rem;
}

.service-list {
  padding: 0;
}

.service-list li {
  padding: 10px;
  margin-bottom: 10px;
}
.main-section {
  background-color: #fff;
  padding: 2rem 0;

  h2 {
    color: #000;
  }

  p {
    color: #555;
  }

  .info-box {
    height: 100%;
    border-radius: 5px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    background-color: #f9f9f9;
    h3 {
      margin-bottom: 1rem;
    }
  }

  .btn-get {
    background-color: #0c3c5d;
    color: #fff;
    border-radius: 0;
    padding: 10px 120px;
    transition: 0.3s;
    &:hover {
      background-color: #055241;
      color: #fff;
      transition: 0.3s;
    }
  }

  .animate__fast {
    animation-duration: 0.5s !important;
  }

  .col {
    width: 100%;
  }

  .col-text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  }

  .col-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-wrapper {
    border-radius: 5px;
    width: 100%;
    height: 0;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;
    overflow: hidden;
  }

  .image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .service-list li {
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  .list-unstyled {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .list-unstyled li {
    padding: 0.5rem 0;
  }
}
</style>
