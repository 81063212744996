<template>
  <Main />
  <div class="home container wow animate__animated animate__fadeIn">
    <About />
    <Contacts />
  </div>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Ensure Bootstrap's JavaScript is imported
import Main from "@/components/Main.vue";
import About from "@/components/About.vue";
import Contacts from "@/components/Contacts.vue";

export default {
  name: "HomeView",
  components: {
    Main,
    About,
    Contacts,
  },
  mounted() {
    new WOW().init();
  },
};
</script>
